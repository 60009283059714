import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

function NavBar(props) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          themeColor
        }
      }
    }
  `)

  const themeColor = data.site.siteMetadata.themeColor

  const { menuLinks, location } = props
  return (
    <div
      style={{
        maxWidth: "200px",
      }}
    >
      <ul
        style={{
          listStyle: "none",
          display: "flex",
          justifyContent: "space-between",
          margin: 0,
          paddingBottom: "1.5rem",
        }}
      >
        {menuLinks.map((link, index) => (
          <li key={index}>
            <Link
              style={{
                color:
                  link && link.link && link.link === location
                    ? themeColor
                    : "black",
                fontSize: "0.85rem",
              }}
              to={link.link}
            >
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default NavBar
