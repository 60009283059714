import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import { rhythm } from "../utils/typography"
import NavBar from "./navbar"


const quotes = [
  
]
function Layout(props) {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          menuLinks {
            link
            name
          }
          themeColor
        }
      }
    }
  `)

  const { menuLinks, themeColor } = data.site.siteMetadata
  const { location, title, children } = props
  const rootPath = `${__PATH_PREFIX__}/`
  let header
  if (location.pathname === rootPath) {
    header = (
      <h2
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
          color: "#212121",
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h2>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
          color: themeColor,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }

  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(30),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>{header}</header>
      <NavBar menuLinks={menuLinks} location={location.pathname} />
      <main>{children}</main>
      <footer
        style={{
          paddingTop: "1.5rem",
          display: "flex",
          justifyContent: "flex-start",
          fontStyle: 'italic',
          fontSize: '0.8rem'
        }}
      >
        <div>
        © {new Date().getFullYear()}, Love you Papa, Mummy & Amma. ❤️
          <p>{quotes[Math.floor(Math.random()*quotes.length)]}</p>
        </div>
      </footer>
    </div>
  )
}

export default Layout
